.inq-main {
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .inq-main {
    margin-top: 30px;
  }
}
.inq-main .inq-wrap {
  padding: 50px;
  margin: 100px 0;
  width: 80%;
  margin: auto;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}
@media screen and (max-width: 1366px) {
  .inq-main .inq-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .inq-main .inq-wrap {
    width: 95%;
    padding: 50px 10px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.inq-main .inq-wrap .form-col {
  background-color: rgba(26, 71, 126, 0.1019607843);
  padding: 50px;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .inq-main .inq-wrap .form-col {
    padding: 30px 0px;
    background-color: transparent;
  }
}
.inq-main .inq-wrap .inq-text {
  text-align: center;
  padding: 0px;
}
.inq-main .inq-wrap .inq-text h1 {
  font-size: 55px;
  font-family: "Hind Guntur", sans-serif;
  text-align: left;
  font-weight: 600;
}
@media screen and (max-width: 1366px) {
  .inq-main .inq-wrap .inq-text h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 768px) {
  .inq-main .inq-wrap .inq-text h1 {
    font-size: 35px;
  }
}
.inq-main .inq-wrap .inq-text p {
  font-size: 18px;
  font-family: "Hind Guntur", sans-serif;
  text-align: left;
  color: #494949;
}
@media screen and (max-width: 768px) {
  .inq-main .inq-wrap .inq-text p {
    font-size: 16px;
  }
}
.inq-main .inq-wrap .inq-text .list-adv {
  margin-top: 30px;
}
.inq-main .inq-wrap .inq-text .list-adv ul > li {
  list-style-type: none;
}
.inq-main .inq-wrap .inq-text .list-adv ul > li p {
  font-size: 18px;
  font-family: "Hind Guntur", sans-serif;
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .inq-main .inq-wrap .inq-text .list-adv ul > li p {
    margin-bottom: 15px;
    font-size: 15px;
  }
}
.inq-main .inq-wrap .inq-text .list-adv ul > li p .check-icon {
  color: #D08C1D;
  margin-right: 10px;
}
.inq-main .inq-wrap .inq-form .form-input {
  margin-bottom: 15px;
}
.inq-main .inq-wrap .inq-form .form-input label {
  font-family: "Hind Guntur", sans-serif;
  margin-top: 10px;
  color: #666666;
}
.inq-main .inq-wrap .inq-form .form-input label span {
  color: #D08C1D;
}
.inq-main .inq-wrap .inq-form .form-input input {
  width: 100%;
  padding: 12px 10px 7px 10px;
  border: none;
  font-size: 16px;
  outline: none;
  color: #201D1D;
  font-family: "Hind Guntur", sans-serif;
  background-color: transparent;
  border-bottom: 1px solid rgba(189, 189, 189, 0.6039215686);
}
.inq-main .inq-wrap .inq-form .form-input select {
  width: 100%;
  padding: 12px 10px 16px 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #3b3b3b;
  border-bottom: 1px solid rgba(189, 189, 189, 0.6039215686);
}
.inq-main .inq-wrap .inq-form .form-input-message label {
  font-family: "Hind Guntur", sans-serif;
  color: #666666;
  margin-top: 10px;
}
.inq-main .inq-wrap .inq-form .form-input-message textarea {
  width: 100%;
  padding: 10px 10px;
  border: none;
  font-size: 16px;
  outline: none;
  color: #201D1D;
  font-family: "Hind Guntur", sans-serif;
  background-color: transparent;
  border-bottom: 1px solid rgba(189, 189, 189, 0.6039215686);
}
.inq-main .inq-wrap .inq-form .submit-inquiry {
  text-align: center;
  margin-top: 50px;
}
.inq-main .inq-wrap .inq-form .submit-inquiry button {
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 11px 20px 7px 20px;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6784313725);
  z-index: 99999;
  display: none;
}
.modal-wrap .modal-box {
  background-color: #ffffff;
  padding: 30px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  text-align: center;
  font-family: "Playfair Display", serif;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 10px;
    width: 80%;
  }
}
.modal-wrap .modal-box .checkIcon {
  font-size: 100px;
  color: #22384F;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box .checkIcon {
    font-size: 80px;
  }
}
.modal-wrap .modal-box h1 {
  font-size: 35px;
  color: #242424;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box h1 {
    font-size: 30px;
  }
}
.modal-wrap .modal-box p {
  font-size: 18px;
  color: #3F3F3F;
  font-family: "Hind Guntur", sans-serif;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box p {
    font-size: 15px;
  }
}
.modal-wrap .modal-box .exit-btn {
  width: 100%;
  text-align: center;
}
.modal-wrap .modal-box .exit-btn button {
  width: 90%;
  margin-top: 15px;
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #22384F;
  color: #ffffff;
  padding: 12px 0 10px 0;
  font-size: 14px;
  font-family: "Hind Guntur", sans-serif;
  cursor: pointer;
  border: 1px solid #22384F;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box .exit-btn button {
    padding: 8px 0;
    font-size: 12px;
  }
}

.modal-wrap.active {
  display: block;
}/*# sourceMappingURL=inquiry.css.map */