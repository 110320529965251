@import '../styles/Variables/Var.scss';

.navbar{
    padding: 10px 0;
    // border-bottom: 1px solid #c9c7c7c0;
    // position: fixed;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    // background-color: transparent;
    background-color: #ffffff;
    // background-color: rgba(255, 255, 255, 0.33);
    // margin-left: 50px;
    box-shadow: 0px 2px 5px #3838384f;

    .logo img{
        width: 250px;
        padding-left: 100px;

        @media screen and (max-width: 780px) {
            padding-left: 10px;
            width: 130px;
        }
    }

    .logo-lc{
        margin-right: 100px;
    }

    .logo-lc img{
        width: 40px;
        height: 70px;

        @media screen and (max-width: 780px) {
            display: none;
        }
    }

    @media screen and (max-width: 780px) {
        margin: auto;
        padding: 0;
        width: 100%;
    }

    .navbar-brand{
        @media screen and (max-width: 780px) {
            margin-left: 10px;
        }
    }

    .nav-link{
        color: #3C332F;
        // color: $blue;
        font-weight: 400;
        font-size: 14px;
        margin: 0 10px;
        font-family: 'Lucida Sans'!important;

        @media screen and (max-width: 1366px) {
            margin: 0 5px;
            font-size: 13px;
        }

        @media screen and (max-width: 780px) {
            color: $gray;
            margin: 0 10px;
        }
    }

    .nav-link:hover{
        color: $yellow;
        transition: 0.3s ease-in-out;
    }

    .btn{
        // @include borderBtn();
        // transition: 0.3s ease-in-out;
        // border: 1px solid $yellow;
        // color: $white;
        // background-color: $yellow;
        // font-family: $oswald;
        // padding: 10px 15px 10px 10px;
        border: 1px solid $yellow;
        outline: navajowhite;
        padding: 5px 20px 7px 20px;
        background-color: transparent;
        color: $yellow;
        font-size: 14px;
        transition: 0.3s ease-in;
        margin-left: 15px;

        @media screen and (max-width: 1366px) {
            margin: 0 5px;
            font-size: 13px;
        }

        @media screen and (max-width: 780px) {
            margin-left: 10px;
            border: 1px solid $yellow;
            background-color: transparent;
        }
    }

    .btn:hover{
        background-color: $yellow;
        color: $white;
    }

    // .btn:hover{
    //     background-color: $yellow;
    //     color: $white;
    // }

    //     .btn:hover{
    //         color: $white;
    //     }

    .navbar-collapse{
        // margin: 0 50px 0 0;

        @media screen and (max-width: 780px) {
            background-color: $white;
            margin: 0;
        }
    }

    .navbar-nav{
        width: 100%;
        justify-content: end;
        display: flex;
        padding-right: 35px;

       @media screen and (max-width: 780px) {
        //   text-align: left;
        //   justify-content: center;
       }
   }

    //    .inquire-btn{
    //         margin-right: 50px;
    //    }

   .navbar-toggler{
        outline: none;
   }

   .navbar-toggler-icon{
        color: $yellow;
        font-size: 34px;
        padding-top: 4px;
   }

   .navbar-toggler-icon:hover{
        color: $yellow;
   }

}







