@import '../styles/Variables/Var.scss';

.gallery-main{
    .gallery-cover{
        // height: 100vh;

        img{
            height: 70vh;
            width: 100%;

            @media screen and (max-width: 768px) {
                height: 40vh;
            }
        }

        .gallery-text{
            text-align: center;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);

            @media screen and (max-width: 768px) {
                top: 25%;
            }

            h1{
                font-size: $mainFS;
                font-family: $playfair;
                color: $white;
                text-shadow: $textshadow;

                @media screen and (max-width: 768px) {
                    font-size: $mobileFS;
                }
            }
        }
    }

    .gally-main-title{
        text-align: center;
        margin-top: 50px;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
        }

        .gally-cont{
            width: 50%;
            margin: auto;

            @media screen and (max-width: 768px) {
                width: 95%;
            }
        }

        h3{
            font-size: 45px;
            font-family: $playfair;
            font-weight: 400;

            @media screen and (max-width: 768px) {
                font-size: 25px;
            }
        }

        p{
            font-family: $hind;
            font-size: 18px;
            padding-top: 5px;
            color: $gray2;
            font-weight: 300;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    .gallery-wrap{
        margin: 80px 200px;

        @media screen and (max-width: 768px){
            margin: 30px 0;
        }

        .gallery{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;

            @media screen and (max-width: 768px){
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 10px;
                padding: 0 10px;
            }
        }

        .boat-img-col{
            position: relative;
            width: 100%;
        }

        .image-card{
            overflow: hidden;
        }

        // .image-card:hover img{
        //     scale: -120%;
        // }

        .image-card img{
            width: 100%;
            transition: scale 700ms;
            object-fit: contain;

            // @media screen and (min-width: 1367px) {
            //     height: 400px;
            // }
        }


        .image-label{
            background-color: rgba(0, 0, 0, 0.493);
            font-family: $playfair;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            color: rgb(255, 255, 255);
            transition: 0.5s ease;
            font-size: 26px;
            // overflow: hidden;
            opacity: 0;
            font-weight: 400;
        }

        .boat-img-col:hover .image-label{
            opacity: 1;
        }


    }
}