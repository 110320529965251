@import '../styles/Variables/Var.scss';

.inq-main{
    margin-top: 100px;

    @media screen and (max-width:768px){
        margin-top: 30px;
    }

    .inq-wrap{
        // background-color: #d08b1d17;
        padding: 50px;
        margin: 100px 0;
        width: 80%;
        margin: auto;
        border-radius: 4px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;

        @media screen and (max-width:1366px){
            width: 100%;
        }

        @media screen and (max-width:768px){
            width: 95%;
            padding: 50px 10px;
            grid-template-columns: repeat(1, 1fr);
        }

        .form-col{
            background-color: #1a477e1a;
            padding: 50px;
            border-radius: 10px;

            @media screen and (max-width:768px){
                padding: 30px 0px;
                background-color: transparent;
            }
        }

        .inq-text{
            text-align: center;
            padding: 0px;

            h1{
                font-size: 55px;
                font-family: $hind;
                text-align: left;
                font-weight: 600;

                @media screen and (max-width:1366px){
                    font-size: 45px;
                }

                @media screen and (max-width:768px){
                    font-size: 35px;
                }
            }

            p{
                font-size: 18px;
                font-family: $hind;
                text-align: left;
                color: #494949;

                @media screen and (max-width:768px){
                    font-size: 16px;
                }
            }

            .list-adv{
                margin-top: 30px;

                ul > li {
                    list-style-type: none;

                    p{
                        font-size: 18px;
                        font-family: $hind;
                        margin-bottom: 25px;

                        @media screen and (max-width: 768px){
                            margin-bottom: 15px;
                            font-size: 15px;
                        }

                        .check-icon{
                            color: $yellow;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .inq-form{
            .form-input{
                margin-bottom: 15px;

                label{
                    font-family: $hind;
                    margin-top: 10px;
                    color: #666666;

                    span{
                        color: $yellow;
                    }
                }

                input{
                    width: 100%;
                    padding: 12px 10px 7px 10px;
                    border: none;
                    font-size: 16px;
                    outline: none;
                    color: $gray;
                    font-family: $hind;
                    background-color: transparent;
                    border-bottom: 1px solid #bdbdbd9a;
                }

                select{
                    width: 100%;
                    padding: 12px 10px 16px 10px;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: $gray2;
                    border-bottom: 1px solid #bdbdbd9a;
                }
            }

            .form-input-message{
                label{
                    font-family: $hind;
                    color: #666666;
                    margin-top: 10px;
                }

                textarea{
                    width: 100%;
                    padding: 10px 10px;
                    border: none;
                    font-size: 16px;
                    outline: none;
                    color: $gray;
                    font-family: $hind;
                    background-color: transparent;
                    border-bottom: 1px solid #bdbdbd9a;
                }
            }

            .submit-inquiry{
                text-align: center;
                margin-top: 50px;

                button{
                    @include solidBtn();
                    padding: 11px 20px 7px 20px;
                }
            }

        }
    }


}

//modal

.modal-wrap{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000ad;
    z-index: 99999;
    display: none;

    .modal-box{
        background-color: #ffffff;
        padding: 30px 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        text-align: center;
        font-family: $playfair;

        @media screen and (max-width: 800px) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 25px 10px;
            width: 80%;
        }

        .checkIcon{
            font-size: 100px;
            color: $blue;

            @media screen and (max-width: 800px) {
                font-size: 80px;
            }
        }

        h1{
            font-size: 35px;
            color: #242424;

            @media screen and (max-width: 800px) {
                font-size: 30px;
            }
        }

        p{
            font-size: 18px;
            color: #3F3F3F;
            font-family: $hind;

            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
        }

        .exit-btn{
            width: 100%;
            text-align: center;

            button{
                width: 90%;
                margin-top: 15px;
                @include solidBtn();
                background-color: $blue;
                color: $white;
                padding: 12px 0 10px 0;
                font-size: 14px;
                font-family: $hind;
                cursor: pointer;
                border: 1px solid $blue;

                @media screen and (max-width: 800px) {
                    padding: 8px 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.modal-wrap.active{
    display: block;
}