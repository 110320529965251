.footer-section {
  background-color: #021527;
  width: 100%;
  padding: 40px 40px 20px 40px;
  color: white;
}
@media screen and (max-width: 768px) {
  .footer-section .logo {
    text-align: center;
  }
}
.footer-section .logo img {
  width: 250px;
}
.footer-section .property-desc {
  font-family: "Hind Guntur", sans-serif;
}
@media screen and (max-width: 768px) {
  .footer-section .property-desc {
    text-align: center;
  }
}
.footer-section .footer-title p {
  font-size: 22px;
  color: #D08C1D;
  font-family: "Hind Guntur", sans-serif;
}
@media screen and (max-width: 780px) {
  .footer-section .footer-title p {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .footer-section .footer-title p {
    text-align: center;
  }
}
.footer-section .footer-title ul > li {
  list-style-type: none;
  font-family: "Hind Guntur", sans-serif;
  padding: 5px 0;
}
@media screen and (max-width: 780px) {
  .footer-section .footer-title ul > li {
    padding: 2px 0;
    text-align: center;
  }
}
.footer-section .footer-title ul > li .shortcuts {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 0;
}
@media screen and (max-width: 780px) {
  .footer-section .footer-title ul > li .shortcuts {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .footer-section .marina-socials {
    text-align: center;
  }
}
.footer-section .marina-socials .social-icons {
  font-size: 28px;
  color: #D08C1D;
  margin-right: 10px;
}
@media screen and (max-width: 780px) {
  .footer-section .link-row {
    margin-top: 30px;
  }
}
.footer-section .copyright {
  text-align: center;
  border-top: 1px solid #D08C1D;
  margin-top: 30px;
}
.footer-section .copyright p {
  padding-top: 30px;
  font-size: 14px;
  font-family: "Hind Guntur", sans-serif;
}/*# sourceMappingURL=Footer.css.map */