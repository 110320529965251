.contact-section {
  padding: 70px 0;
}
.contact-section .section-text {
  text-align: center;
}
.contact-section .section-text h1 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 30px;
  color: #201D1D;
}
@media screen and (max-width: 768px) {
  .contact-section .section-text h1 {
    font-size: 24px;
  }
}
.contact-section .section-text button {
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 10px 20px 7px 20px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 768px) {
  .contact-section .section-text button {
    font-size: 12px;
  }
}
.contact-section .section-text button:hover {
  background-color: #D08C1D;
  color: #ffffff;
}

.contact-main img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .contact-main img {
    height: 40vh;
  }
}
.contact-main .contact-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-shadow: none;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-text {
    top: 20%;
    left: 10%;
    transform: translate(0%, -20%);
  }
}
.contact-main .contact-text h1 {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-text h1 {
    font-size: 12vw;
  }
}
.contact-main .contact-wrap {
  margin: 50px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap {
    margin: 50px 10px;
  }
}
.contact-main .contact-wrap .discover-text {
  font-family: "Playfair Display", serif;
  color: #D08C1D;
  opacity: 0.3;
  font-size: 100px;
}
.contact-main .contact-wrap .contact-title {
  margin-bottom: 40px;
}
.contact-main .contact-wrap .contact-title span {
  font-family: "Hind Guntur", sans-serif;
}
.contact-main .contact-wrap .contact-title h1 {
  font-family: "Playfair Display", serif;
  font-size: 45px;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-title h1 {
    font-size: 25px;
  }
}
.contact-main .contact-wrap .contact-title p {
  font-size: 18px;
  font-family: "Hind Guntur", sans-serif;
  font-weight: lighter;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-title p {
    font-size: 16px;
  }
}
.contact-main .contact-wrap .water-icon {
  width: 100px;
  height: auto;
}
.contact-main .contact-wrap .contact-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 70%;
  margin: 100px auto 0px auto;
}
@media screen and (max-width: 1366px) {
  .contact-main .contact-wrap .contact-row {
    width: 100%;
    margin: 60px auto 0px auto;
  }
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-row {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 60px auto 0px auto;
  }
}
.contact-main .contact-wrap .contact-row .contact-col {
  display: flex;
  text-align: left;
}
.contact-main .contact-wrap .contact-row .contact-col img {
  width: 60px;
  height: 60px;
  margin: 0px 10px;
}
@media screen and (max-width: 1366px) {
  .contact-main .contact-wrap .contact-row .contact-col img {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-row .contact-col img {
    width: 40px;
    height: 40px;
  }
}
.contact-main .contact-wrap .contact-row .contact-col .contact-info h4 {
  font-size: 24px;
  font-family: "Hind Guntur", sans-serif;
  color: #201D1D;
}
@media screen and (max-width: 1366px) {
  .contact-main .contact-wrap .contact-row .contact-col .contact-info h4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-row .contact-col .contact-info h4 {
    font-size: 20px;
  }
}
.contact-main .contact-wrap .contact-row .contact-col .contact-info p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  color: #3b3b3b;
}
@media screen and (max-width: 768px) {
  .contact-main .contact-wrap .contact-row .contact-col .contact-info p {
    font-size: 16px;
  }
}
@media screen and (max-width: 1366px) {
  .contact-main .contact-wrap .contact-row .contact-col .contact-info p {
    font-size: 15px;
  }
}
.contact-main .email-wrap {
  background-color: rgba(26, 71, 126, 0.0784313725);
  padding: 80px 0;
}
@media screen and (max-width: 768px) {
  .contact-main .email-wrap {
    padding: 40px 0;
  }
}
.contact-main .email-wrap .email-row {
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}
@media screen and (max-width: 1366px) {
  .contact-main .email-wrap .email-row {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .contact-main .email-wrap .email-row {
    width: 100%;
    flex-direction: column;
  }
}
.contact-main .email-wrap .email-row .email-col {
  flex: 1;
  margin: 0 10px;
}
.contact-main .email-wrap .email-row .email-col h1 {
  font-family: "Playfair Display", serif;
  font-size: 35px;
  text-align: left;
  color: #201D1D;
}
@media screen and (max-width: 768px) {
  .contact-main .email-wrap .email-row .email-col h1 {
    text-align: center;
    font-size: 25px;
  }
}
.contact-main .email-wrap .email-row .email-col p {
  font-size: 18px;
  text-align: left;
  color: #3b3b3b;
}
@media screen and (max-width: 768px) {
  .contact-main .email-wrap .email-row .email-col p {
    text-align: center;
    font-size: 16px;
  }
}
.contact-main .email-wrap .email-row .email-sub form {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}
.contact-main .email-wrap .email-row .email-sub input {
  padding: 10px 10px 8px 10px;
  border: 1px solid rgba(128, 128, 128, 0.322);
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  color: #201D1D;
  border-radius: 4px;
  background-color: transparent;
}
@media screen and (max-width: 768px) {
  .contact-main .email-wrap .email-row .email-sub input {
    padding: 8px 8px 6px 8px;
  }
}
.contact-main .email-wrap .email-row .email-sub .button-news {
  background-color: #D08C1D;
  border: none;
  outline: none;
  color: #ffffff;
  font-family: "Hind Guntur", sans-serif;
  padding: 10px 10px 8px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.contact-main .map-frame iframe {
  border: none;
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 1367px) {
  .contact-main .map-frame iframe {
    height: 600px;
  }
}
.contact-main .booking-wrap {
  margin: 80px 0;
}
@media screen and (max-width: 1366px) {
  .contact-main .booking-wrap {
    margin: 40px 0 80px 0;
  }
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap {
    margin: 50px 0;
  }
}
.contact-main .booking-wrap .booking-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  margin: auto;
  grid-gap: 40px;
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap .booking-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
.contact-main .booking-wrap .booking-col h1 {
  font-size: 55px;
  font-family: "Playfair Display", serif;
  color: #22384F;
  padding: 20px 0;
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap .booking-col h1 {
    text-align: center;
    font-size: 35px;
  }
}
.contact-main .booking-wrap .booking-col input {
  width: 100%;
  font-family: "Hind Guntur", sans-serif;
  padding: 10px 10px 5px 10px;
  margin-bottom: 15px;
  color: #201D1D;
  border: 1px solid rgba(128, 128, 128, 0.2196078431);
  outline: none;
  border-radius: 4px;
}
.contact-main .booking-wrap .booking-col select {
  width: 100%;
  padding: 12px 10px 12px 10px;
  background-color: transparent;
  border: 1px solid rgba(128, 128, 128, 0.2196078431);
  outline: none;
  border-radius: 4px;
  color: #3b3b3b;
  margin-bottom: 15px;
}
.contact-main .booking-wrap .booking-col textarea {
  width: 100%;
  font-family: "Hind Guntur", sans-serif;
  padding: 10px 10px 5px 10px;
  margin-bottom: 10px;
  color: #201D1D;
  border: 1px solid rgba(128, 128, 128, 0.2196078431);
  outline: none;
  border-radius: 4px;
}
.contact-main .booking-wrap .booking-col .submit-booking {
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap .booking-col .submit-booking {
    text-align: center;
  }
}
.contact-main .booking-wrap .booking-col .submit-booking button {
  background-color: #D08C1D;
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 12px 40px 8px 40px;
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap .booking-col .submit-booking button {
    font-size: 12px;
    padding: 10px 40px 7px 40px;
  }
}
.contact-main .booking-wrap .booking-col img {
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-width: 768px) {
  .contact-main .booking-wrap .booking-col img {
    display: none;
  }
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6784313725);
  z-index: 99999;
  display: none;
}
.modal-wrap .modal-box {
  background-color: #ffffff;
  padding: 30px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  text-align: center;
  font-family: "Playfair Display", serif;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 10px;
    width: 80%;
  }
}
.modal-wrap .modal-box .checkIcon {
  font-size: 100px;
  color: #22384F;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box .checkIcon {
    font-size: 80px;
  }
}
.modal-wrap .modal-box h1 {
  font-size: 35px;
  color: #242424;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box h1 {
    font-size: 30px;
  }
}
.modal-wrap .modal-box p {
  font-size: 18px;
  color: #3F3F3F;
  font-family: "Hind Guntur", sans-serif;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box p {
    font-size: 15px;
  }
}
.modal-wrap .modal-box .exit-btn {
  width: 100%;
  text-align: center;
}
.modal-wrap .modal-box .exit-btn button {
  width: 90%;
  margin-top: 15px;
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  background-color: #22384F;
  color: #ffffff;
  padding: 12px 0 10px 0;
  font-size: 14px;
  font-family: "Hind Guntur", sans-serif;
  cursor: pointer;
  border: 1px solid #22384F;
}
@media screen and (max-width: 800px) {
  .modal-wrap .modal-box .exit-btn button {
    padding: 8px 0;
    font-size: 12px;
  }
}

.modal-wrap.active {
  display: block;
}/*# sourceMappingURL=Contact.css.map */