.destination-main .dest-cover img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .destination-main .dest-cover img {
    height: 40vh;
  }
}
.destination-main .dest-cover .dest-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}
@media screen and (max-width: 768px) {
  .destination-main .dest-cover .dest-text {
    top: 25%;
  }
}
.destination-main .dest-cover .dest-text h1 {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  text-shadow: none;
}
@media screen and (max-width: 768px) {
  .destination-main .dest-cover .dest-text h1 {
    font-size: 12vw;
  }
}
.destination-main .destination-list .dest-list {
  width: 50%;
  margin: 150px auto;
}
@media screen and (max-width: 1366px) {
  .destination-main .destination-list .dest-list {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list {
    width: 95%;
    margin: 50px auto;
  }
}
.destination-main .destination-list .dest-list .dest-img {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .dest-img {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
}
.destination-main .destination-list .dest-list .dest-img img {
  width: 450px;
  -o-object-fit: contain;
     object-fit: contain;
  box-shadow: 0 3px 3px rgba(51, 51, 51, 0.4862745098);
  position: relative;
  top: -70px;
  right: -40px;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .dest-img img {
    top: 0px;
    right: 0px;
    width: 100%;
  }
}
.destination-main .destination-list .dest-list .dest-img .dest-details {
  background-color: rgba(175, 174, 174, 0.1764705882);
  padding: 100px 50px 0px 50px;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .dest-img .dest-details {
    margin-top: 20px;
    padding: 50px 10px;
    text-align: center;
  }
}
.destination-main .destination-list .dest-list .dest-img .dest-details h3 {
  font-family: "Playfair Display", serif;
  font-size: 35px;
  color: #22384F;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .dest-img .dest-details h3 {
    font-size: 25px;
  }
}
.destination-main .destination-list .dest-list .dest-img .dest-details p {
  font-family: "Hind Guntur", sans-serif;
  color: #3b3b3b;
  padding-top: 20px;
  font-weight: 200;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .dest-img .dest-details p {
    font-size: 16px;
  }
}
.destination-main .destination-list .dest-list .dest-img .dest-details button {
  margin-top: 20px;
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
}
.destination-main .destination-list .dest-list .order-2-dest {
  grid-template-columns: 2fr 1fr;
  margin-bottom: 230px;
}
@media screen and (max-width: 1366px) {
  .destination-main .destination-list .dest-list .order-2-dest {
    margin-bottom: 250px;
  }
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .order-2-dest {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }
}
.destination-main .destination-list .dest-list .order-2-dest img {
  width: 450px;
  -o-object-fit: contain;
     object-fit: contain;
  box-shadow: 0 3px 3px rgba(51, 51, 51, 0.4862745098);
  position: relative;
  top: 0px;
  right: 0px;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .order-2-dest img {
    width: 100%;
  }
}
.destination-main .destination-list .dest-list .order-2-dest .dest-details {
  position: relative;
  top: 70px;
  right: -40px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .destination-main .destination-list .dest-list .order-2-dest .dest-details {
    top: 0px;
    right: 0px;
    text-align: center;
    order: 1;
  }
}
.destination-main .access-details {
  width: 70%;
  margin: auto;
  margin-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .destination-main .access-details {
    width: 100%;
  }
}
.destination-main .access-details h1 {
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #22384F;
  font-size: 36px;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .destination-main .access-details h1 {
    font-size: 25px;
  }
}
.destination-main .access-details ul > li {
  font-size: 18px;
  font-family: "Hind Guntur", sans-serif;
  padding: 5px 0;
  color: #3b3b3b;
}
@media screen and (max-width: 768px) {
  .destination-main .access-details ul > li {
    font-size: 16px;
  }
}/*# sourceMappingURL=Destinations.css.map */