.navbar {
  padding: 10px 0;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(56, 56, 56, 0.3098039216);
}
.navbar .logo img {
  width: 250px;
  padding-left: 100px;
}
@media screen and (max-width: 780px) {
  .navbar .logo img {
    padding-left: 10px;
    width: 130px;
  }
}
.navbar .logo-lc {
  margin-right: 100px;
}
.navbar .logo-lc img {
  width: 40px;
  height: 70px;
}
@media screen and (max-width: 780px) {
  .navbar .logo-lc img {
    display: none;
  }
}
@media screen and (max-width: 780px) {
  .navbar {
    margin: auto;
    padding: 0;
    width: 100%;
  }
}
@media screen and (max-width: 780px) {
  .navbar .navbar-brand {
    margin-left: 10px;
  }
}
.navbar .nav-link {
  color: #3C332F;
  font-weight: 400;
  font-size: 14px;
  margin: 0 10px;
  font-family: "Lucida Sans" !important;
}
@media screen and (max-width: 1366px) {
  .navbar .nav-link {
    margin: 0 5px;
    font-size: 13px;
  }
}
@media screen and (max-width: 780px) {
  .navbar .nav-link {
    color: #201D1D;
    margin: 0 10px;
  }
}
.navbar .nav-link:hover {
  color: #D08C1D;
  transition: 0.3s ease-in-out;
}
.navbar .btn {
  border: 1px solid #D08C1D;
  outline: navajowhite;
  padding: 5px 20px 7px 20px;
  background-color: transparent;
  color: #D08C1D;
  font-size: 14px;
  transition: 0.3s ease-in;
  margin-left: 15px;
}
@media screen and (max-width: 1366px) {
  .navbar .btn {
    margin: 0 5px;
    font-size: 13px;
  }
}
@media screen and (max-width: 780px) {
  .navbar .btn {
    margin-left: 10px;
    border: 1px solid #D08C1D;
    background-color: transparent;
  }
}
.navbar .btn:hover {
  background-color: #D08C1D;
  color: #ffffff;
}
@media screen and (max-width: 780px) {
  .navbar .navbar-collapse {
    background-color: #ffffff;
    margin: 0;
  }
}
.navbar .navbar-nav {
  width: 100%;
  justify-content: end;
  display: flex;
  padding-right: 35px;
}
.navbar .navbar-toggler {
  outline: none;
}
.navbar .navbar-toggler-icon {
  color: #D08C1D;
  font-size: 34px;
  padding-top: 4px;
}
.navbar .navbar-toggler-icon:hover {
  color: #D08C1D;
}/*# sourceMappingURL=Nav.css.map */