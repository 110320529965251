@import '../styles/Variables/Var.scss';

.about-main{

    .about-cover{
        // height: 100vh;

        img{
            height: 70vh;
            width: 100%;

            @media screen and (max-width: 768px) {
                height: 40vh;
            }
        }

        .about-text{
            text-align: center;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);

            @media screen and (max-width: 768px) {
                top: 25%;
            }

            h1{
                font-size: $mainFS;
                font-family: $playfair;
                color: $white;
                text-shadow: $textshadow;

                @media screen and (max-width: 768px) {
                    font-size: $mobileFS
                }
            }
        }
    }

    .value-section{
        height: 100%;
        margin-top: 50px;

        .vsec{

        }

        .about-btn{
            @include borderBtn();
            padding: 11px 20px 7px 20px;
            transition: 0.3s ease-in;

            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 10px 20px 7px 20px;
            }
        }

        .about-btn:hover{
            background-color: $yellow;
            color: white;
        }

        .value-sec-a{
            position: absolute;
            top: 50%;
            left: 10%;
            transform: translate(-10%, -50%);

            @media screen and (max-width: 768px) {
                position: unset;
                top: 0;
                left: 0;
                transform: translate(0%, 0%);
                margin-bottom: 30px;
            }

            label{
                font-size: 16px;
                font-family: $hind;
                color: $yellow;
            }

            h3{
                font-family: $playfair;
                font-size: 55px;
                color: $gray;
                font-weight: 400;

                @media screen and (max-width: 768px) {
                    font-size: 36px;
                }
            }

            p{
                font-family: $hind;
                font-size: 18px;
                color: $gray2;
                padding-top: 10px;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .value-sec-b{

            .value-a{
                display: flex;
                justify-content: space-around;
                margin: 20px 0;

                img{
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                    margin-top: 15px;
                }

                .value-icon{
                    color: $yellow;
                    font-size: 55px;
                    margin: auto 20px;
                }

                .value-text{
                    h3{
                        font-family: $hind;
                        color: $gray;
                        font-weight: 400;
                        font-size: 24px;
                    }

                    p{
                        font-family: $hind;
                        font-size: 16px;
                        color: $gray2;
                    }
                }
            }
        }
    }

    .story-wrap{
        margin: 50px 0;
        background-color: $backGround;

        .story-row{
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .story-col-a{
                img{
                    width: 100%;
                    object-fit: contain;
                }
            }

            .story-col-b{
                .story-text{
                    position: relative;
                    top: 50%;
                    left: 10%;
                    transform: translate(-10%, -50%);
                    padding: 0 20px;

                    @media screen and (max-width: 1366px){
                        padding: 10px 20px;
                    }

                    @media screen and (max-width: 768px){
                        padding: 20px 10px;
                    }

                    label{
                        font-size: 16px;
                        font-family: $hind;
                        color: $yellow;
                    }

                    h3{
                        font-family: $playfair;
                        font-size: 35px;
                        color: $gray;
                        font-weight: 400;
                        margin-bottom: 15px;

                        @media screen and (max-width: 768px) {
                            font-size: 26px;
                        }
                    }

                    p{
                        font-family: $hind;
                        font-size: 16px;
                        color: $gray2;

                        @media screen and (max-width: 1366px) {
                            font-size: 16px;
                        }

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    button{
                        border: none;
                        border-bottom: 1px solid $yellow;
                        padding: 10px 2px 2px 2px;
                        background-color: transparent;
                        cursor: pointer;
                        outline: none;
                        font-family: $hind;
                        font-weight: 500;
                        color: $yellow;
                        transition: 0.3s ease-in;
                    }
                }
            }
        }
    }
}