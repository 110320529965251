.about-main .about-cover img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .about-main .about-cover img {
    height: 40vh;
  }
}
.about-main .about-cover .about-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}
@media screen and (max-width: 768px) {
  .about-main .about-cover .about-text {
    top: 25%;
  }
}
.about-main .about-cover .about-text h1 {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  text-shadow: none;
}
@media screen and (max-width: 768px) {
  .about-main .about-cover .about-text h1 {
    font-size: 12vw;
  }
}
.about-main .value-section {
  height: 100%;
  margin-top: 50px;
}
.about-main .value-section .about-btn {
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 11px 20px 7px 20px;
  transition: 0.3s ease-in;
}
@media screen and (max-width: 768px) {
  .about-main .value-section .about-btn {
    font-size: 12px;
    padding: 10px 20px 7px 20px;
  }
}
.about-main .value-section .about-btn:hover {
  background-color: #D08C1D;
  color: white;
}
.about-main .value-section .value-sec-a {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
}
@media screen and (max-width: 768px) {
  .about-main .value-section .value-sec-a {
    position: unset;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    margin-bottom: 30px;
  }
}
.about-main .value-section .value-sec-a label {
  font-size: 16px;
  font-family: "Hind Guntur", sans-serif;
  color: #D08C1D;
}
.about-main .value-section .value-sec-a h3 {
  font-family: "Playfair Display", serif;
  font-size: 55px;
  color: #201D1D;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .about-main .value-section .value-sec-a h3 {
    font-size: 36px;
  }
}
.about-main .value-section .value-sec-a p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  color: #3b3b3b;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .about-main .value-section .value-sec-a p {
    font-size: 16px;
  }
}
.about-main .value-section .value-sec-b .value-a {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}
.about-main .value-section .value-sec-b .value-a img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-top: 15px;
}
.about-main .value-section .value-sec-b .value-a .value-icon {
  color: #D08C1D;
  font-size: 55px;
  margin: auto 20px;
}
.about-main .value-section .value-sec-b .value-a .value-text h3 {
  font-family: "Hind Guntur", sans-serif;
  color: #201D1D;
  font-weight: 400;
  font-size: 24px;
}
.about-main .value-section .value-sec-b .value-a .value-text p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 16px;
  color: #3b3b3b;
}
.about-main .story-wrap {
  margin: 50px 0;
  background-color: #f1f1f1;
}
.about-main .story-wrap .story-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 768px) {
  .about-main .story-wrap .story-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
.about-main .story-wrap .story-row .story-col-a img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.about-main .story-wrap .story-row .story-col-b .story-text {
  position: relative;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  padding: 0 20px;
}
@media screen and (max-width: 1366px) {
  .about-main .story-wrap .story-row .story-col-b .story-text {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 768px) {
  .about-main .story-wrap .story-row .story-col-b .story-text {
    padding: 20px 10px;
  }
}
.about-main .story-wrap .story-row .story-col-b .story-text label {
  font-size: 16px;
  font-family: "Hind Guntur", sans-serif;
  color: #D08C1D;
}
.about-main .story-wrap .story-row .story-col-b .story-text h3 {
  font-family: "Playfair Display", serif;
  font-size: 35px;
  color: #201D1D;
  font-weight: 400;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .about-main .story-wrap .story-row .story-col-b .story-text h3 {
    font-size: 26px;
  }
}
.about-main .story-wrap .story-row .story-col-b .story-text p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 16px;
  color: #3b3b3b;
}
@media screen and (max-width: 1366px) {
  .about-main .story-wrap .story-row .story-col-b .story-text p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .about-main .story-wrap .story-row .story-col-b .story-text p {
    font-size: 16px;
  }
}
.about-main .story-wrap .story-row .story-col-b .story-text button {
  border: none;
  border-bottom: 1px solid #D08C1D;
  padding: 10px 2px 2px 2px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-weight: 500;
  color: #D08C1D;
  transition: 0.3s ease-in;
}/*# sourceMappingURL=About.css.map */