.gallery-main .gallery-cover img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .gallery-main .gallery-cover img {
    height: 40vh;
  }
}
.gallery-main .gallery-cover .gallery-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}
@media screen and (max-width: 768px) {
  .gallery-main .gallery-cover .gallery-text {
    top: 25%;
  }
}
.gallery-main .gallery-cover .gallery-text h1 {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  text-shadow: none;
}
@media screen and (max-width: 768px) {
  .gallery-main .gallery-cover .gallery-text h1 {
    font-size: 12vw;
  }
}
.gallery-main .gally-main-title {
  text-align: center;
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .gallery-main .gally-main-title {
    margin-top: 30px;
  }
}
.gallery-main .gally-main-title .gally-cont {
  width: 50%;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .gallery-main .gally-main-title .gally-cont {
    width: 95%;
  }
}
.gallery-main .gally-main-title h3 {
  font-size: 45px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .gallery-main .gally-main-title h3 {
    font-size: 25px;
  }
}
.gallery-main .gally-main-title p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  padding-top: 5px;
  color: #3b3b3b;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .gallery-main .gally-main-title p {
    font-size: 16px;
  }
}
.gallery-main .gallery-wrap {
  margin: 80px 200px;
}
@media screen and (max-width: 768px) {
  .gallery-main .gallery-wrap {
    margin: 30px 0;
  }
}
.gallery-main .gallery-wrap .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
@media screen and (max-width: 768px) {
  .gallery-main .gallery-wrap .gallery {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    padding: 0 10px;
  }
}
.gallery-main .gallery-wrap .boat-img-col {
  position: relative;
  width: 100%;
}
.gallery-main .gallery-wrap .image-card {
  overflow: hidden;
}
.gallery-main .gallery-wrap .image-card img {
  width: 100%;
  transition: scale 700ms;
  -o-object-fit: contain;
     object-fit: contain;
}
.gallery-main .gallery-wrap .image-label {
  background-color: rgba(0, 0, 0, 0.493);
  font-family: "Playfair Display", serif;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  transition: 0.5s ease;
  font-size: 26px;
  opacity: 0;
  font-weight: 400;
}
.gallery-main .gallery-wrap .boat-img-col:hover .image-label {
  opacity: 1;
}/*# sourceMappingURL=Gallery.css.map */