@import '../styles/Variables/Var.scss';

.destination-main{
    .dest-cover{
        // height: 100vh;

        img{
            height: 70vh;
            width: 100%;

            @media screen and (max-width: 768px) {
                height: 40vh;
            }
        }

        .dest-text{
            text-align: center;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);

            @media screen and (max-width: 768px) {
                top: 25%;
            }

            h1{
                font-size: $mainFS;
                font-family: $playfair;
                color: $white;
                text-shadow: $textshadow;

                @media screen and (max-width: 768px) {
                    font-size: $mobileFS;
                }
            }
        }
    }

    .destination-list{
        .dest-list{
            width: 50%;
            margin: 150px auto;

            @media screen and (max-width: 1366px) {
                width: 70%;
            }

            @media screen and (max-width: 768px) {
                width: 95%;
                margin: 50px auto;
            }


            .dest-img{
                display: grid;
                grid-template-columns: 1fr 2fr;
                margin-bottom: 100px;

                @media screen and (max-width: 768px) {
                    grid-template-columns: 1fr;
                    margin-bottom: 50px;
                }

                img{
                    width: 450px;
                    object-fit: contain;
                    box-shadow: 0 3px 3px #3333337c;
                    position: relative;
                    top: -70px;
                    right: -40px;

                    @media screen and (max-width: 768px) {
                        top: 0px;
                        right: 0px;
                        width: 100%;
                    }
                }

                .dest-details{
                    background-color: #afaeae2d;
                    padding: 100px 50px 0px 50px;
                    text-align: right;

                    @media screen and (max-width: 768px) {
                        margin-top: 20px;
                        padding: 50px 10px;
                        text-align: center;
                    }

                    h3{
                        font-family: $playfair;
                        font-size: 35px;
                        color: $blue;

                        @media screen and (max-width: 768px) {
                            font-size: 25px;
                        }
                    }

                    p{
                        font-family: $hind;
                        color: $gray2;
                        padding-top: 20px;
                        font-weight: 200;

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    button{
                        margin-top: 20px;
                        @include borderBtn();
                    }
                }
            }

            .order-2-dest{
                grid-template-columns: 2fr 1fr;
                margin-bottom: 230px;

                @media screen and (max-width: 1366px) {
                    margin-bottom: 250px;
                }

                @media screen and (max-width: 768px) {
                    grid-template-columns: 1fr;
                    margin-bottom: 10px;
                }

                img{
                    width: 450px;
                    object-fit: contain;
                    box-shadow: 0 3px 3px #3333337c;
                    position: relative;
                    top: 0px;
                    right: 0px;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }

                .dest-details{
                    position: relative;
                    top: 70px;
                    right: -40px;
                    text-align: left;

                    @media screen and (max-width: 768px) {
                        top: 0px;
                        right: 0px;
                        text-align: center;
                        order: 1;
                    }
                }
            }
        }
    }

    .access-details{
        width: 70%;
        margin: auto;
        margin-bottom: 100px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        h1{
            text-align: center;
            font-family: $playfair;
            color: $blue;
            font-size: 36px;
            margin-bottom: 20px;

            @media screen and (max-width: 768px) {
                font-size: 25px;
            }
        }

        ul > li {
            font-size: 18px;
            font-family: $hind;
            padding: 5px 0;
            color: $gray2;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

}