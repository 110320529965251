@import '../styles/Variables/Var.scss';

//offered
.offered{
    height: auto;
    padding: 50px;
    background-color: rgb(233, 233, 233);

    @media screen and (max-width: 768px){
        padding: 50px 0;
    }

    .o-title{
        text-align: center;
        font-family: $hind;
        width: 45%;
        margin: auto;
        padding-bottom: 30px;

        @media screen and (max-width: 768px){
            width: 100%;
        }

        span{
            text-align: center;
            color: $yellow;
            font-family: $hind;
        }

        h3{
            font-size: 36px;
            font-weight: 400;
            font-family: $playfair;
            margin-bottom: 20px;

            @media screen and (max-width: 768px){
                font-size: 24px;
                padding: 0 10px;
                margin-bottom: 0px;
            }
        }
    }

    .offer-types{
        @media screen and (max-width: 768px){
            padding: 0 10px;
        }

        .offer-row{
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            @media screen and (max-width: 768px){
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .offer{
            text-align: center;
            padding: 20px 10px;
        }

        .bb{
            border-bottom: 1px solid #d1d1d1;
        }

        .mb{
            @media screen and (max-width: 768px) {
                border-bottom: 1px solid #d1d1d1;
            }
        }

        .bd{
            border-right: 1px solid #d1d1d1;

            @media screen and (max-width: 768px) {
                border-right: none;
            }
        }

        .yacht-icon{
            font-size: 40px;
            color: $yellow
        }

        .y-desc{
            h4{
                font-family: $playfair;
                color: $blue;
                font-weight: 600;
                font-size: 20px;
            }

            p{
                font-family: $hind;
                color: #505050;
                margin-top: 15px;
            }
        }
    }
}

//modal

.package-wrap{
    background-color: #000000c5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    // display: none;
    opacity: 0;
    transition: 350ms ease-in-out;
    pointer-events: none;
    will-change: transform, opacity;
    transform: scale(1.1);
    transition:
    transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    overflow: hidden;

    .close-modal{
        text-align: right;

        button{
            background-color: transparent;
            border: none;
            outline: none;
        }

        .closeIcon{
            color: #ffffff;
            font-size: 35px;
            margin: 10px 10px 0 0;
            cursor: pointer;
        }
    }

    .package-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .package-details{
            img{
                height: auto;
                width: 600px;

                @media screen and (max-width: 1366px) {
                    height: auto;
                    width: 600px;
                }

                @media screen and (max-width: 768px) {
                    height: auto;
                    width: 95vw;
                }
            }
        }
    }
}

.package-wrap.active{
    overflow: hidden;
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
    transition:
    transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.clickable-offer{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

}
.clickable-offer:focus{
    outline: none;

}