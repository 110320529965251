@import '../styles/Variables/Var.scss';

.member-wrap{
    height: auto;
    width: 50%;
    margin: 100px auto;

    @media screen and (max-width: 1366px) {
        width: 80%;
    }

    @media screen and (max-width: 768px) {
        width: 95%;
        margin: 60px auto;
    }

    .member-title{
        text-align: center;
        padding: 20px 0;

        h1{
            font-family: $playfair;
            font-size: 35px;
            font-weight: 500;
            color: $gray;
            color: $blue;

            @media screen and (max-width: 768px) {
                font-size: 28px;
            }
        }

        p{
            font-family: $hind;
            font-size: 25px;
            padding-top: 10px;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    .member-form{
        padding: 10px 20px;

        @media screen and (max-width: 768px){
            padding: 0;
        }

        input{
            border-radius: 4px;
            outline: none;
            border: 1px solid #b9b9b981;
            padding: 7px 8px 10px 8px;
            margin-bottom: 8px;
            color: $gray;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        label{
            font-family: $hind;
            font-size: 14px;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }

        .form-row{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;

            .form-col{
                input{
                    width: 100%;
                }
            }
        }

        .form-row-b{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;

            .form-col{
                input{
                    width: 100%;
                }
            }
        }

        .form-row-c{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            .form-col{
                input{
                    width: 100%;
                }
            }
        }

        .form-cabin{
            border: 1px solid #b9b9b98f;
            padding: 10px;
            border-radius: 4px;
            margin: 10px 0;

            p{
                text-align: center;
                font-family: $hind;
                font-weight: bold;
            }
        }

        .form-row-d{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;

            .form-col{
                input{
                    width: 100%;
                }
            }
        }

        .marina-btn{
            text-align: center;
            margin-top: 30px;

            .submit-member{
                width: 30%;
                background-color: #F1AE3C;
                color: white;
                outline: none;
                border: none;
                font-family: $hind;
                padding: 12px 5px 8px 5px;
                font-size: 16px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
}