.offered {
  height: auto;
  padding: 50px;
  background-color: rgb(233, 233, 233);
}
@media screen and (max-width: 768px) {
  .offered {
    padding: 50px 0;
  }
}
.offered .o-title {
  text-align: center;
  font-family: "Hind Guntur", sans-serif;
  width: 45%;
  margin: auto;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .offered .o-title {
    width: 100%;
  }
}
.offered .o-title span {
  text-align: center;
  color: #D08C1D;
  font-family: "Hind Guntur", sans-serif;
}
.offered .o-title h3 {
  font-size: 36px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .offered .o-title h3 {
    font-size: 24px;
    padding: 0 10px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .offered .offer-types {
    padding: 0 10px;
  }
}
.offered .offer-types .offer-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 768px) {
  .offered .offer-types .offer-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
.offered .offer-types .offer {
  text-align: center;
  padding: 20px 10px;
}
.offered .offer-types .bb {
  border-bottom: 1px solid #d1d1d1;
}
@media screen and (max-width: 768px) {
  .offered .offer-types .mb {
    border-bottom: 1px solid #d1d1d1;
  }
}
.offered .offer-types .bd {
  border-right: 1px solid #d1d1d1;
}
@media screen and (max-width: 768px) {
  .offered .offer-types .bd {
    border-right: none;
  }
}
.offered .offer-types .yacht-icon {
  font-size: 40px;
  color: #D08C1D;
}
.offered .offer-types .y-desc h4 {
  font-family: "Playfair Display", serif;
  color: #22384F;
  font-weight: 600;
  font-size: 20px;
}
.offered .offer-types .y-desc p {
  font-family: "Hind Guntur", sans-serif;
  color: #505050;
  margin-top: 15px;
}

.package-wrap {
  background-color: rgba(0, 0, 0, 0.7725490196);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 0;
  transition: 350ms ease-in-out;
  pointer-events: none;
  will-change: transform, opacity;
  transform: scale(1.1);
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  overflow: hidden;
}
.package-wrap .close-modal {
  text-align: right;
}
.package-wrap .close-modal button {
  background-color: transparent;
  border: none;
  outline: none;
}
.package-wrap .close-modal .closeIcon {
  color: #ffffff;
  font-size: 35px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.package-wrap .package-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.package-wrap .package-box .package-details img {
  height: auto;
  width: 600px;
}
@media screen and (max-width: 1366px) {
  .package-wrap .package-box .package-details img {
    height: auto;
    width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .package-wrap .package-box .package-details img {
    height: auto;
    width: 95vw;
  }
}

.package-wrap.active {
  overflow: hidden;
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

.clickable-offer {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.clickable-offer:focus {
  outline: none;
}/*# sourceMappingURL=Offer.css.map */