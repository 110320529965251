.member-wrap {
  height: auto;
  width: 50%;
  margin: 100px auto;
}
@media screen and (max-width: 1366px) {
  .member-wrap {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .member-wrap {
    width: 95%;
    margin: 60px auto;
  }
}
.member-wrap .member-title {
  text-align: center;
  padding: 20px 0;
}
.member-wrap .member-title h1 {
  font-family: "Playfair Display", serif;
  font-size: 35px;
  font-weight: 500;
  color: #201D1D;
  color: #22384F;
}
@media screen and (max-width: 768px) {
  .member-wrap .member-title h1 {
    font-size: 28px;
  }
}
.member-wrap .member-title p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 25px;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .member-wrap .member-title p {
    font-size: 18px;
  }
}
.member-wrap .member-form {
  padding: 10px 20px;
}
@media screen and (max-width: 768px) {
  .member-wrap .member-form {
    padding: 0;
  }
}
.member-wrap .member-form input {
  border-radius: 4px;
  outline: none;
  border: 1px solid rgba(185, 185, 185, 0.5058823529);
  padding: 7px 8px 10px 8px;
  margin-bottom: 8px;
  color: #201D1D;
}
@media screen and (max-width: 768px) {
  .member-wrap .member-form input {
    font-size: 14px;
  }
}
.member-wrap .member-form label {
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .member-wrap .member-form label {
    font-size: 12px;
  }
}
.member-wrap .member-form .form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.member-wrap .member-form .form-row .form-col input {
  width: 100%;
}
.member-wrap .member-form .form-row-b {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}
.member-wrap .member-form .form-row-b .form-col input {
  width: 100%;
}
.member-wrap .member-form .form-row-c {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.member-wrap .member-form .form-row-c .form-col input {
  width: 100%;
}
.member-wrap .member-form .form-cabin {
  border: 1px solid rgba(185, 185, 185, 0.5607843137);
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}
.member-wrap .member-form .form-cabin p {
  text-align: center;
  font-family: "Hind Guntur", sans-serif;
  font-weight: bold;
}
.member-wrap .member-form .form-row-d {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.member-wrap .member-form .form-row-d .form-col input {
  width: 100%;
}
.member-wrap .member-form .marina-btn {
  text-align: center;
  margin-top: 30px;
}
.member-wrap .member-form .marina-btn .submit-member {
  width: 30%;
  background-color: #F1AE3C;
  color: white;
  outline: none;
  border: none;
  font-family: "Hind Guntur", sans-serif;
  padding: 12px 5px 8px 5px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}/*# sourceMappingURL=member.css.map */