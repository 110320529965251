.hero-vid {
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .hero-vid {
    margin-top: 60px;
  }
}

.wtr-icon img {
  width: 90px;
}

.cover-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Playfair Display", serif;
  width: 70%;
  padding: 20px 10px;
  border-radius: 2px;
  text-align: left;
}
@media screen and (max-width: 1366px) {
  .cover-desc {
    width: 70%;
    top: 55%;
  }
}
@media screen and (max-width: 1280px) and (max-height: 720px) {
  .cover-desc {
    top: 55%;
  }
}
@media screen and (max-width: 1000px) {
  .cover-desc {
    top: 37%;
  }
}
@media screen and (max-width: 810px) {
  .cover-desc {
    top: 25%;
  }
}
@media screen and (max-width: 768px) {
  .cover-desc {
    width: 100%;
    text-align: center;
    padding: 0 10px;
    top: 30%;
  }
}
@media screen and (max-width: 601px) and (max-height: 962px) {
  .cover-desc {
    top: 25%;
  }
}
@media screen and (max-width: 1280px) and (max-height: 800px) {
  .cover-desc {
    top: 45%;
  }
}
@media screen and (max-width: 550px) {
  .cover-desc {
    top: 25%;
  }
}
@media screen and (max-width: 450px) {
  .cover-desc {
    top: 22%;
  }
}
@media screen and (max-width: 375px) {
  .cover-desc {
    top: 25%;
  }
}
@media screen and (max-width: 360px) {
  .cover-desc {
    top: 25%;
  }
}
.cover-desc label {
  color: #ffffff;
  font-size: 22px;
}
@media screen and (max-width: 780px) {
  .cover-desc label {
    font-size: 16px;
  }
}
.cover-desc h3 {
  font-size: 5vw;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-family: Open "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
@media screen and (max-width: 768px) {
  .cover-desc h3 {
    font-size: 6vw;
  }
}
.cover-desc span {
  color: #1A477E;
}
.cover-desc p {
  font-size: 22px;
  color: #ffffff;
  font-weight: 300;
  font-family: "Hind Guntur", sans-serif;
}
@media screen and (max-width: 780px) {
  .cover-desc p {
    font-size: 12px;
  }
}
.cover-desc .heroBtn {
  font-size: 24px;
  margin-top: 20px;
  border: none;
  outline: none;
  padding: 10px 5px 5px 5px;
  border-bottom: 3px solid #D08C1D;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s ease-in;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .cover-desc .heroBtn {
    margin-top: 0px;
    font-size: 16px;
    border-bottom: 2px solid #D08C1D;
  }
}
.cover-desc .heroBtn:hover {
  color: #D08C1D;
  border-bottom: 5px solid #ffffff;
}
.cover-desc .discoverBtn {
  padding: 15px 0;
  cursor: pointer;
}

.lc-logo {
  width: 100%;
  text-align: right;
  position: relative;
}
.lc-logo img {
  width: 60px;
  height: auto;
  position: absolute;
  top: 100%;
  right: 2%;
  transform: translate(-2%, -120%);
}
@media screen and (max-width: 768px) {
  .lc-logo img {
    width: 30px;
  }
}

.destination-section {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.destination-section .image-box {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}
@media screen and (max-width: 768px) {
  .destination-section .ddest {
    order: -1;
    margin-bottom: 20px;
  }
}
.destination-section .des-image img {
  width: 100%;
  height: 70vh;
}
@media screen and (max-width: 768px) {
  .destination-section .des-image img {
    width: 100%;
    height: auto;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
.destination-section .dest-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 768px) {
  .destination-section .dest-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
.destination-section .dest-text {
  margin: auto;
  position: relative;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  padding: 0 30px;
}
@media screen and (max-width: 768px) {
  .destination-section .dest-text {
    position: unset;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 10px;
  }
}
.destination-section .dest-text span {
  font-family: "Hind Guntur", sans-serif;
  color: #D08C1D;
  margin-bottom: 10px;
}
.destination-section .dest-text h3 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  color: #201D1D;
  font-size: 36px;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .destination-section .dest-text h3 {
    font-size: 24px;
  }
}
.destination-section .dest-text p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .destination-section .dest-text p {
    font-size: 16px;
  }
}
.destination-section .dest-text .destBtn button {
  border: 1px solid #D08C1D;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  padding: 10px 30px;
  color: #D08C1D;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .destination-section .dest-text .destBtn button {
    font-size: 12px;
    padding: 8px 30px;
  }
}
.destination-section .dest-text .destBtn button:hover {
  background-color: #D08C1D;
  color: #ffffff;
}

.contact-page {
  position: relative;
}
.contact-page .image-holder img {
  width: 100%;
  background-position: center;
  margin: 0;
  padding: 0;
  height: 80vh;
}
@media screen and (max-width: 768px) {
  .contact-page .image-holder img {
    height: 65vh;
  }
}
.contact-page .contact-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 0 10px;
  }
}
.contact-page .contact-desc .icon-holder .yatcon {
  font-size: 50px;
  color: #D08C1D;
}
.contact-page .contact-desc .icon-holder img {
  width: 60px;
  height: 100px;
  margin-bottom: 20px;
}
.contact-page .contact-desc .icon-holder h3 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc .icon-holder h3 {
    font-size: 24px;
  }
}
.contact-page .contact-desc .icon-holder p {
  font-family: "Hind Guntur", sans-serif;
  font-weight: 300;
  font-size: 18px;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc .icon-holder p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc .icon-holder .contact-btn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.contact-page .contact-desc .icon-holder .contact-btn .contacts {
  background-color: #D08C1D;
  padding: 12px 40px 7px 40px;
  font-family: "Hind Guntur", sans-serif;
  cursor: pointer;
  color: #ffffff;
  outline: none;
  border: 1px solid #D08C1D;
  transition: 0.3s ease-in;
  border-radius: 4px;
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc .icon-holder .contact-btn .contacts {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 10px 40px 5px 40px;
  }
}
.contact-page .contact-desc .icon-holder .contact-btn .yachtForm {
  margin: 0 5px;
  padding: 12px 10px 7px 10px;
  background-color: transparent;
  color: #ffffff;
  font-family: "Hind Guntur", sans-serif;
  border: none;
  border-bottom: 1px solid white;
  cursor: pointer;
  transition: 0.3s ease-in;
  outline: none;
}
@media screen and (max-width: 768px) {
  .contact-page .contact-desc .icon-holder .contact-btn .yachtForm {
    font-size: 12px;
  }
}
.contact-page .contact-desc .icon-holder .contact-btn .contacts:hover {
  background-color: transparent;
  color: #D08C1D;
}
.contact-page .contact-desc .icon-holder .contact-btn .yachtForm:hover {
  color: #D08C1D;
  border-bottom: 1px solid #D08C1D;
}

.destContainer {
  background-color: #e9e9e9;
  padding: 80px 30px;
}
@media screen and (max-width: 768px) {
  .destContainer {
    padding: 40px 0;
  }
}
.destContainer .destTitle {
  text-align: center;
  width: 60%;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .destContainer .destTitle {
    width: 100%;
  }
}
.destContainer .destTitle span {
  font-size: 16px;
  font-family: "Hind Guntur", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 500;
  margin-bottom: 10px;
  color: #D08C1D;
}
.destContainer .destTitle h3 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  color: #201D1D;
}
@media screen and (max-width: 768px) {
  .destContainer .destTitle h3 {
    font-size: 24px;
  }
}
.destContainer .carouselActivities {
  padding: 30px 0;
  margin: 10px 100px;
  margin-bottom: 30px;
  position: relative;
  left: 0;
  right: 0;
}
@media screen and (max-width: 1366px) {
  .destContainer .carouselActivities {
    margin: 10px;
  }
}
@media screen and (max-width: 768px) {
  .destContainer .carouselActivities {
    padding: 10px 0;
    margin: 0 0 30px 0;
  }
}
.destContainer .carouselActivities .rentalBox {
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.4235294118);
}
@media screen and (max-width: 768px) {
  .destContainer .carouselActivities .rentalBox {
    margin: 0;
  }
}
.destContainer .carouselActivities .rentalBox img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.destContainer .carouselActivities .rentalBox h1 {
  font-size: 18px;
  text-align: center;
  padding: 20px 0 10px 0;
  font-family: "Hind Guntur", sans-serif;
}
.destContainer .dest-description {
  width: 50%;
  margin: auto;
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .destContainer .dest-description {
    width: 100%;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.destContainer .dest-btn {
  text-align: center;
}
.destContainer .dest-btn button {
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 11px 20px 7px 20px;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 768px) {
  .destContainer .dest-btn button {
    font-size: 12px;
  }
}
.destContainer .dest-btn button:hover {
  background-color: #D08C1D;
  color: #ffffff;
}

.slick-slide div {
  margin: 0 1.5rem;
}
@media screen and (max-width: 1366px) {
  .slick-slide div {
    margin: 0 0.9rem;
  }
}

.slick-dots li.slick-active button:before {
  color: #D08C1D !important;
}

@media screen and (max-width: 768px) {
  .slick-prev {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .slick-next {
    display: none !important;
  }
}/*# sourceMappingURL=Hero.css.map */