.bt-cover img {
  height: 70vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .bt-cover img {
    height: 40vh;
  }
}
.bt-cover .bt-text {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}
@media screen and (max-width: 768px) {
  .bt-cover .bt-text {
    top: 25%;
  }
}
.bt-cover .bt-text h1 {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  text-shadow: none;
}
@media screen and (max-width: 768px) {
  .bt-cover .bt-text h1 {
    font-size: 12vw;
  }
}

.boat-page {
  height: auto;
  margin-bottom: 70px;
  margin-top: 0px;
}
.boat-page .boat-tag {
  text-align: center;
  width: 50%;
  margin: 20px auto;
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 60px;
  }
}
.boat-page .boat-tag h3 {
  font-size: 36px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag h3 {
    font-size: 24px;
  }
}
.boat-page .boat-tag p {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #575757;
  color: #201D1D;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag .boat-btn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    margin: auto;
  }
}
.boat-page .boat-tag .boat-btn .boat-seemore {
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  margin: 0 10px;
  padding: 8px 40px 5px 40px;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag .boat-btn .boat-seemore {
    margin: 10px auto;
    width: 70%;
  }
}
.boat-page .boat-tag .boat-btn .boat-seemore button {
  color: #D08C1D;
  cursor: pointer;
}
.boat-page .boat-tag .boat-btn .boat-book {
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  margin: 0 10px;
  padding: 8px 40px 5px 40px;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag .boat-btn .boat-book {
    margin: auto;
    width: 70%;
  }
}
.boat-page .boat-tag .boat-btn .boat-book button {
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag .boat-btn .boat-book button {
    font-size: 12px;
  }
}
.boat-page .boat-tag .boat-btn button {
  background-color: transparent;
  border: none;
  outline: none;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-tag .boat-btn button {
    font-size: 12px;
  }
}
.boat-page .boat-row {
  margin: 50px;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row {
    margin: 0 5px;
  }
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box {
    margin-bottom: 25px;
  }
}
.boat-page .boat-row .boat-box img {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .boat-page .boat-row .boat-box img {
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box img {
    height: 300px;
  }
}
.boat-page .boat-row .boat-box .boat-handler {
  position: relative;
}
.boat-page .boat-row .boat-box .boat-handler:hover .boat-btn {
  background-color: rgba(0, 0, 0, 0.555);
  border-radius: 4px;
  transition: 0.3s ease-in;
  cursor: pointer;
  opacity: 1;
}
.boat-page .boat-row .boat-box .boat-btn {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  top: 0;
  text-align: center;
  transition: 0.3s ease-in-out;
  opacity: 0;
}
.boat-page .boat-row .boat-box .boat-btn .btn-abslt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.boat-page .boat-row .boat-box .boat-btn .btn-wrp {
  margin: 10px;
  transition: 0.3s ease-in-out;
}
.boat-page .boat-row .boat-box .boat-btn .specs-btn {
  border-radius: 0;
  outline: none;
  border: 1px solid #D08C1D;
  color: #ffffff;
  background-color: #D08C1D;
  cursor: pointer;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 12px 20px 7px 20px;
  width: 300px;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box .boat-btn .specs-btn {
    width: 200px;
  }
}
.boat-page .boat-row .boat-box .boat-btn .book-btn {
  border-radius: 0;
  border: 1px solid #D08C1D;
  color: #D08C1D;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-family: "Hind Guntur", sans-serif;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 4px;
  padding: 12px 20px 7px 20px;
  color: #ffffff;
  width: 300px;
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box .boat-btn .book-btn {
    width: 200px;
  }
}
.boat-page .boat-row .boat-box h5 {
  font-family: "Hind Guntur", sans-serif;
  font-size: 18px;
  color: #201D1D;
  margin-bottom: 5px;
}
.boat-page .boat-row .boat-box h4 {
  font-family: "Playfair Display", serif;
  font-size: 26px;
  color: #22384F;
}
.boat-page .boat-row .boat-box p {
  font-size: 16px;
  font-family: "Hind Guntur", sans-serif;
  color: rgb(97, 97, 97);
  padding-top: 5px;
}
.boat-page .boat-row .boat-box .spec-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.boat-page .boat-row .boat-box .spec-details .specs-col {
  display: flex;
  text-align: center;
  align-self: center;
}
.boat-page .boat-row .boat-box .spec-details .specs-col .boatIcon {
  font-size: 18px;
  margin: 5px;
  color: #22384F;
}
@media screen and (max-width: 1440px) {
  .boat-page .boat-row .boat-box .spec-details .specs-col .boatIcon {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box .spec-details .specs-col .boatIcon {
    font-size: 16px;
  }
}
.boat-page .boat-row .boat-box .spec-details .specs-col p {
  font-size: 16px;
  color: #22384F;
}
@media screen and (max-width: 1440px) {
  .boat-page .boat-row .boat-box .spec-details .specs-col p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .boat-page .boat-row .boat-box .spec-details .specs-col p {
    font-size: 13px;
  }
}/*# sourceMappingURL=Boats.css.map */