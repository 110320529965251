@import '../styles/Variables/Var.scss';

//boats

.bt-cover{
    // height: 100vh;

    img{
        height: 70vh;
        width: 100%;

        @media screen and (max-width: 768px) {
            height: 40vh;
        }
    }

    .bt-text{
        text-align: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);

        @media screen and (max-width: 768px) {
            top: 25%;
        }

        h1{
            font-size: $mainFS;
            font-family: $playfair;
            color: $white;
            text-shadow: $textshadow;

            @media screen and (max-width: 768px) {
                font-size: $mobileFS;
            }
        }
    }
}

.boat-page{
    height: auto;
    margin-bottom: 70px;
    margin-top: 0px;

    .boat-tag{
        text-align: center;
        width: 50%;
        margin: 20px auto;
        padding-top: 40px;

        @media screen and (max-width: 768px){
            width: 100%;
            padding-top: 20px;
            margin-bottom: 60px;
        }

        h3{
            font-size: 36px;
            font-family: $playfair;
            font-weight: 400;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        p{
            font-family: $hind;
            font-size: 18px;
            font-weight: 300;
            color: #575757;
            color: $gray;
            margin-top: 10px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        .boat-btn{
            @media screen and (max-width: 768px){
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                text-align: center;
                margin: auto;
            }

            .boat-seemore{
                @media screen and (max-width: 768px){
                    margin: 10px auto;
                    width: 70%;
                }

                @include borderBtn();
                margin: 0 10px;
                padding: 8px 40px 5px 40px;
                width: 50%;

                button{
                    color: $yellow;
                    cursor: pointer;
                }
            }


            .boat-book{
                @media screen and (max-width: 768px){
                    margin: auto;
                    width: 70%;
                }

                @include solidBtn();
                margin: 0 10px;
                padding: 8px 40px 5px 40px;
                width: 50%;

                button{
                    color: $white;
                    cursor: pointer;

                    @media screen and (max-width: 768px){
                        font-size: 12px;
                    }
                }
            }

            button{
                background-color: transparent;
                border: none;
                outline: none;

                @media screen and (max-width: 768px){
                    font-size: 12px;
                }
            }
        }
    }

    .boat-row{
        margin: 50px;

        @media screen and (max-width: 768px) {
            margin: 0 5px;
        }

        .boat-box{

            @media screen and (max-width: 768px) {
                margin-bottom: 25px;
            }

            img{
                width: 100%;
                height: 400px;
                margin-bottom: 20px;
                border-radius: 5px;
                cursor: pointer;

                @media screen and (max-width: 1440px) {
                    height: 300px;
                }

                @media screen and (max-width: 768px) {
                    height: 300px;
                }
            }

            // img:hover{
            //     transition: 0.3s ease-in-out;
            //     filter: blur(1px);
            //     filter: brightness(0.4);
            // }


            //btns
            .boat-handler{
                position: relative;
            }

            .boat-handler:hover .boat-btn{
                background-color: rgba(0, 0, 0, 0.555);
                border-radius: 4px;
                transition: 0.3s ease-in;
                cursor: pointer;
                opacity: 1;
            }

            .boat-btn{
                position: absolute;
                bottom: 20px;
                right: 0;
                left: 0;
                top: 0;
                text-align: center;
                transition: 0.3s ease-in-out;
                opacity: 0;

                .btn-abslt{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .btn-wrp{
                    margin: 10px;
                    transition: 0.3s ease-in-out;
                }

                .specs-btn{
                    @include solidBtn();
                    padding: 12px 20px 7px 20px;
                    width: 300px;

                    @media screen and (max-width: 768px) {
                        width: 200px;
                    }
                }

                .book-btn{
                    @include borderBtn();
                    padding: 12px 20px 7px 20px;
                    color: $white;
                    width: 300px;

                    @media screen and (max-width: 768px) {
                        width: 200px;
                    }
                }
            }


            h5{
                font-family: $hind;
                font-size: 18px;
                color: $gray;
                margin-bottom: 5px;
            }

            h4{
                font-family: $playfair;
                font-size: 26px;
                color: $blue;
            }

            p{
                font-size: 16px;
                font-family: $hind;
                color: rgb(97, 97, 97);
                padding-top: 5px;
            }

            .spec-details{
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .specs-col{
                    display: flex;
                    text-align: center;
                    align-self: center;

                    .boatIcon{
                        font-size: 18px;
                        margin: 5px;
                        color: $blue;

                        @media screen and (max-width: 1440px){
                            font-size: 16px;
                        }

                        @media screen and (max-width: 768px){
                            font-size: 16px;
                        }
                    }

                    p{
                        font-size: 16px;
                        color: $blue;

                        @media screen and (max-width: 1440px){
                            font-size: 14px;
                        }

                        @media screen and (max-width: 768px){
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}