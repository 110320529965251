//palette
$white: #ffffff;
$backGround: #f1f1f1;
$blue: #22384F;
$yellow: #D08C1D;
$gray: #201D1D;
$gray2: #3b3b3b;

// Yellow (D08C1D)
// Dark Blue (22384F)
// Light Blue (778DA9)

//font
$hind: 'Hind Guntur', sans-serif;
$subFont: 'Lucida Sans';
$playfair: 'Playfair Display', serif;


//font size of ceach web page cover section
$mainFS: 6vw;
$mobileFS: 12vw;

// $textshadow: 1px 1px 4px #292929a2;
$textshadow: none;

//button with border
@mixin borderBtn{
    border-radius: 0;
    border: 1px solid $yellow;
    color: $yellow;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-family: $hind;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 4px;
}

@mixin solidBtn{
    border-radius: 0;
    outline: none;
    border: 1px solid $yellow;
    color: $white;
    background-color: $yellow;
    cursor: pointer;
    font-family: $hind;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 4px;
}