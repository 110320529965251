@import '../styles/Variables/Var.scss';

.contact-section{
    // background-color: $backGround;
    padding: 70px 0;

    .section-text{
        text-align: center;

        h1{
            font-family: $playfair;
            font-weight: 400;
            font-size: 36px;
            margin-bottom: 30px;
            color: $gray;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        button{
            @include borderBtn();
            padding: 10px 20px 7px 20px;
            transition: 0.3s ease-in-out;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }

        button:hover{
            background-color: $yellow;
            color: $white;
        }

    }
}

//contact page
.contact-main{
    img{
        height: 70vh;
        width: 100%;

        @media screen and (max-width: 768px) {
            height: 40vh;
        }
    }

    .contact-text{
        text-align: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        text-shadow: $textshadow;

        @media screen and (max-width: 768px) {
            top: 20%;
            left: 10%;
            transform: translate(0%, -20%);
        }

        h1{
            font-size: $mainFS;
            font-family: $playfair;
            color: $white;

            @media screen and (max-width: 768px) {
                font-size: $mobileFS;
            }
        }
    }

    .contact-wrap{
        margin: 50px;
        text-align: center;

        @media screen and (max-width: 768px){
            margin: 50px 10px;
        }

        .discover-text{
            font-family: $playfair;
            color: $yellow;
            opacity: 0.3;
            font-size: 100px;

        }

        .contact-title{
            margin-bottom: 40px;

            span{
                font-family: $hind;
            }

            h1{
                font-family: $playfair;
                font-size: 45px;

                @media screen and (max-width: 768px) {
                    font-size: 25px;
                }
            }

            p{
                font-size: 18px;
                font-family: $hind;
                font-weight: lighter;
                margin-bottom: 20px;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        .water-icon{
            width: 100px;
            height: auto;
        }

        .contact-row{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 70%;
            margin: 100px auto 0px auto;

            @media screen and (max-width: 1366px) {
                width: 100%;
                margin: 60px auto 0px auto;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
                margin: 60px auto 0px auto;
            }

            .contact-col{
                display: flex;
                text-align: left;

                img{
                    width: 60px;
                    height: 60px;
                    margin: 0px 10px;

                    @media screen and (max-width: 1366px) {
                        width: 50px;
                        height: 50px;
                    }

                    @media screen and (max-width: 768px) {
                        width: 40px;
                        height: 40px;
                    }
                }

                .contact-info{
                    h4{
                        font-size: 24px;
                        font-family: $hind;
                        color: $gray;

                        @media screen and (max-width: 1366px) {
                            font-size: 18px;
                        }

                        @media screen and (max-width: 768px) {
                            font-size: 20px;
                        }
                    }

                    p{
                        font-family: $hind;
                        font-size: 18px;
                        color: $gray2;

                        @media screen and (max-width: 768px) {
                            font-size: 16px;
                        }

                        @media screen and (max-width: 1366px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    //EMAIL
    .email-wrap{
        background-color: #1a477e14;
        padding: 80px 0;

        @media screen and (max-width: 768px) {
            padding: 40px 0;
        }

        .email-row{
            display: flex;
            width: 70%;
            margin: auto;
            justify-content: space-around;
            text-align: center;
            align-items: center;

            @media screen and (max-width: 1366px) {
                width: 90%;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
                flex-direction: column;
            }

            .email-col{
                flex: 1;
                margin:  0 10px;

                h1{
                    font-family: $playfair;
                    font-size: 35px;
                    text-align: left;
                    color: $gray;

                    @media screen and (max-width: 768px) {
                        text-align: center;
                        font-size: 25px;
                    }
                }

                p{
                    font-size: 18px;
                    text-align: left;
                    color: $gray2;

                    @media screen and (max-width: 768px) {
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }

            .email-sub{

                form{
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    grid-gap: 10px;
                }

                input{
                    padding: 10px 10px 8px 10px;
                    border: 1px solid rgba(128, 128, 128, 0.322);
                    outline: none;
                    font-family: $hind;
                    color: $gray;
                    border-radius: 4px;
                    background-color: transparent;

                    @media screen and (max-width: 768px) {
                        padding: 8px 8px 6px 8px;
                    }
                }

                .button-news{
                    background-color: $yellow;
                    border: none;
                    outline: none;
                    color: $white;
                    font-family: $hind;
                    padding: 10px 10px 8px 10px;
                    cursor: pointer;
                    border-radius: 4px;
                }
            }
        }
    }

    .map-frame{
        iframe{
            border: none;
            padding: 0;
            margin: 0;

            @media screen and (min-width: 1367px) {
                height: 600px;
            }
        }
    }
//BOOKING
.booking-wrap{
    margin: 80px 0;

    @media screen and (max-width: 1366px) {
        margin: 40px 0 80px 0;
    }

    @media screen and (max-width: 768px) {
        margin: 50px 0;
    }

    .booking-row{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        margin: auto;
        grid-gap: 40px;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .booking-col{
        h1{
            font-size: 55px;
            font-family: $playfair;
            color: $blue;
            padding: 20px 0;

            @media screen and (max-width: 768px) {
                text-align: center;
                font-size: 35px;
            }
        }

        input{
            width: 100%;
            font-family: $hind;
            padding: 10px 10px 5px 10px;
            margin-bottom: 15px;
            color: $gray;
            border: 1px solid #80808038;
            outline: none;
            border-radius: 4px;
        }

        select{
            width: 100%;
            padding: 12px 10px 12px 10px;
            background-color: transparent;
            border: 1px solid #80808038;
            outline: none;
            border-radius: 4px;
            color: $gray2;
            margin-bottom: 15px;
        }

        textarea{
            width: 100%;
            font-family: $hind;
            padding: 10px 10px 5px 10px;
            margin-bottom: 10px;
            color: $gray;
            border: 1px solid #80808038;
            outline: none;
            border-radius: 4px;
        }

        .submit-booking{
            margin-top: 20px;

            @media screen and (max-width: 768px) {
                text-align: center;
            }

            button{
                background-color: $yellow;
                @include solidBtn();
                padding: 12px 40px 8px 40px;

                @media screen and (max-width: 768px) {
                    font-size: 12px;
                    padding: 10px 40px 7px 40px;
                }
            }
        }

        img{
            object-fit: contain;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

}

}




//modal

.modal-wrap{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000ad;
    z-index: 99999;
    display: none;

    .modal-box{
        background-color: #ffffff;
        padding: 30px 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        text-align: center;
        font-family: $playfair;

        @media screen and (max-width: 800px) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 25px 10px;
            width: 80%;
        }

        .checkIcon{
            font-size: 100px;
            color: $blue;

            @media screen and (max-width: 800px) {
                font-size: 80px;
            }
        }

        h1{
            font-size: 35px;
            color: #242424;

            @media screen and (max-width: 800px) {
                font-size: 30px;
            }
        }

        p{
            font-size: 18px;
            color: #3F3F3F;
            font-family: $hind;

            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
        }

        .exit-btn{
            width: 100%;
            text-align: center;

            button{
                width: 90%;
                margin-top: 15px;
                @include solidBtn();
                background-color: $blue;
                color: $white;
                padding: 12px 0 10px 0;
                font-size: 14px;
                font-family: $hind;
                cursor: pointer;
                border: 1px solid $blue;

                @media screen and (max-width: 800px) {
                    padding: 8px 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.modal-wrap.active{
    display: block;
}