@import '../styles/Variables/Var.scss';

.hero-vid{
    height: auto;
    // filter: saturate(200%);
    object-fit: contain;
    width: 100%;

    @media screen and (max-width: 800px){
        margin-top: 60px;
    }
}

.wtr-icon img{
    width: 90px;
}

.cover-desc{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $playfair;
    width: 70%;
    padding: 20px 10px;
    border-radius: 2px;
    text-align: left;


    @media screen and (max-width: 1366px) {
        width: 70%;
        top: 55%;
    }

    //desktop

    @media screen and (max-width: 1280px) and (max-height: 720px) {
        top: 55%;
    }

    @media screen and (max-width: 1000px) {
        top: 37%;
    }

    @media screen and (max-width: 810px) {
        top: 25%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding: 0 10px;
        // background-color: transparent;
        top: 30%;
    }

    //tablet
    @media screen and (max-width: 601px) and (max-height: 962px) {
        top: 25%;
    }

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        top: 45%;
    }

    @media screen and (max-width: 550px) {
        top: 25%;
    }

    @media screen and (max-width: 450px) {
        top: 22%;
    }

    @media screen and (max-width: 375px) {
        top: 25%;
    }

    @media screen and (max-width: 360px) {
        top: 25%;
    }

    label{
        color: $white;
        font-size: 22px;

        @media screen and (max-width: 780px) {
            font-size: 16px;
        }
    }

    h3{
        font-size: 5vw;
        text-align: center;
        color: #ffffff;
        font-weight: 500;
        font-family: Open 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        // text-shadow: 3px 3px 2px #1f2d4725;

        @media screen and (max-width: 768px) {
            font-size: 6vw;
        }
    }

    span{
        color: #1A477E;
        // color: $yellow;
    }

    p{
        font-size: 22px;
        color: $white;
        font-weight: 300;
        font-family: $hind;

        @media screen and (max-width: 780px) {
            font-size: 12px;
        }
    }

    .heroBtn{
        // padding: 15px 40px;
        // border: none;
        // background-color: $yellow;
        // color: $white;
        // cursor: pointer;
        // border: 1px solid $yellow;
        // margin-top: 30px;

        // @media screen and (max-width: 780px) {
        //     width: 100%;
        //     font-size: 13px;
        //     margin-top: 10px;

        // }

        font-size: 24px;
        margin-top: 20px;
        border: none;
        outline: none;
        padding: 10px 5px 5px 5px;
        border-bottom: 3px solid $yellow;
        background-color: transparent;
        color: $white;
        cursor: pointer;
        transition: 0.3s ease-in;
        border-radius: 4px;

        @media screen and (max-width: 768px){
            margin-top: 0px;
            font-size: 16px;
            border-bottom: 2px solid $yellow;
        }
    }

    .heroBtn:hover{
        color: $yellow;
        border-bottom: 5px solid $white;
    }

    .discoverBtn{
        padding: 15px 0;
        cursor: pointer;
    }

    // button:hover{
    //     color: $white;
    // }

}

.lc-logo{
    width: 100%;
    text-align: right;
    position: relative;

    img{
        width: 60px;
        height: auto;
        position: absolute;
        top: 100%;
        right: 2%;
        transform: translate(-2%, -120%);

        @media screen and (max-width: 768px){
            width: 30px;
        }
    }
}

//destinations
.destination-section{
    background-color: #ffffff;
    // padding: 50px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .image-box{
        margin: 0;
        padding: 0;
        box-sizing: 0;
    }

    .ddest{
        @media screen and (max-width: 768px){
            order: -1;
            margin-bottom: 20px;
        }
    }

    .des-image{

        img{
            width: 100%;
            height: 70vh;

            @media screen and (max-width: 768px){
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .dest-row{
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media screen and (max-width: 768px){
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .dest-text{
        margin: auto;
        position: relative;
        top: 50%;
        left: 10%;
        transform: translate(-10%, -50%);
        padding: 0 30px;

        @media screen and (max-width: 768px){
            position: unset;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            margin-top: 30px;
            margin-bottom: 30px;
            padding: 0 10px;
        }

        span{
            font-family: $hind;
            color: $yellow;
            margin-bottom: 10px;
        }

        h3{
            font-family: $playfair;
            font-weight: 400;
            color: $gray;
            font-size: 36px;
            padding-top: 10px;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        p{
            font-family: $hind;
            font-size: 18px;
            padding-top: 10px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        .destBtn button{
            border: 1px solid $yellow;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            padding: 10px 30px;
            color: $yellow;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            border-radius: 4px;

            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 8px 30px;
            }
        }

        .destBtn button:hover{
            background-color: $yellow;
            color: $white;
        }

    }
}

//contact
.contact-page{
    position: relative;

    .image-holder{
        img{
            width: 100%;
            background-position: center;
            margin: 0;
            padding: 0;
            height: 80vh;

            @media screen and (max-width: 768px){
                height: 65vh;
            }
        }
    }

    .contact-desc{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;

        @media screen and (max-width: 768px){
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            padding: 0 10px;
        }

        .icon-holder{
            .yatcon{
                font-size: 50px;
                color: $yellow;
            }

            img{
                width: 60px;
                height: 100px;
                margin-bottom: 20px;
            }

            h3{
                font-family: $playfair;
                font-size: 36px;
                font-weight: 400;

                @media screen and (max-width: 768px){
                    font-size: 24px;
                }
            }

            p{
                font-family: $hind;
                font-weight: 300;
                font-size: 18px;
                padding-top: 10px;

                @media screen and (max-width: 768px){
                    font-size: 16px;
                }
            }

            .contact-btn{
                @media screen and (max-width: 768px){
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                }

                .contacts{
                    background-color: $yellow;
                    padding: 12px 40px 7px 40px;
                    font-family: $hind;
                    cursor: pointer;
                    color: $white;
                    outline: none;
                    border: 1px solid $yellow;
                    transition: 0.3s ease-in;
                    border-radius: 4px;
                    margin: 0 5px;

                    @media screen and (max-width: 768px){
                        font-size: 12px;
                        margin-bottom: 20px;
                        padding: 10px 40px 5px 40px;
                    }
                }

                .yachtForm{
                    margin: 0 5px;
                    padding: 12px 10px 7px 10px;
                    background-color: transparent;
                    color: $white;
                    font-family: $hind;
                    border: none;
                    border-bottom: 1px solid white;
                    cursor: pointer;
                    transition: 0.3s ease-in;
                    outline: none;

                    @media screen and (max-width: 768px){
                        font-size: 12px;
                    }
                }

                .contacts:hover{
                    background-color: transparent;
                    color: $yellow;
                }

                .yachtForm:hover{
                    color: $yellow;
                    border-bottom: 1px solid $yellow;
                }

            }
        }
    }
}

//about carousel
.destContainer{
    background-color: #e9e9e9;
    padding: 80px 30px;

    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }

    .destTitle{
        text-align: center;
        width: 60%;
        margin: auto;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        span{
            font-size: 16px;
            font-family: $hind;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            font-weight: 500;
            margin-bottom: 10px;
            color: $yellow;
        }

        h3{
            font-family: $playfair;
            font-size: 36px;
            color: $gray;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
    }

    .carouselActivities{
        padding: 30px 0;
        margin: 10px 100px;
        margin-bottom: 30px;
        position: relative;
        left: 0;
        right: 0;

        @media screen and (max-width: 1366px){
            margin: 10px;
        }

        @media screen and (max-width: 768px){
            padding: 10px 0;
            margin: 0 0 30px 0;
        }

        .rentalBox{
            margin: 10px;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 1px 3px 5px #8080806c;

            @media screen and (max-width: 768px){
                margin: 0;
            }

            img{
                object-fit: contain;
                width: 100%;
                border-radius: 10px 10px 0 0;
            }

            h1{
                font-size: 18px;
                text-align: center;
                padding: 20px 0 10px 0;
                font-family: $hind;
            }

        }
    }

    .dest-description{
        width: 50%;
        margin: auto;
        font-family: $hind;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;

        @media screen and (max-width: 768px) {
            width: 100%;
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 10px;
        }
    }

    .dest-btn{
        text-align: center;

        button{
            @include borderBtn();
            padding: 11px 20px 7px 20px;
            transition: 0.3s ease-in-out;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }

        button:hover{
            background-color: $yellow;
            color: $white;
        }
    }
}

//carousel

.slick-slide div{
    margin: 0 1.5rem;

    @media screen and (max-width: 1366px) {
        margin: 0 .9rem;
    }
}

.slick-dots li.slick-active button:before{
    color: $yellow !important;
}

.slick-prev{
    @media screen and (max-width: 768px){
        display: none !important;
    }
}

.slick-next{
    @media screen and (max-width: 768px){
        display: none !important;
    }
}