@import '../styles/Variables/Var.scss';

.footer-section{
    // background-color: $blue;
    background-color: #021527;
    width: 100%;
    padding: 40px 40px 20px 40px;
    color: white;

    .logo{
        @media screen and (max-width: 768px) {
            text-align: center;
        }
    }

    .logo img{
        width: 250px;
    }

    .property-desc{
        font-family: $hind;

        @media screen and (max-width: 768px){
            text-align: center;
        }
    }

    .footer-title p{
        font-size: 22px;
        color: $yellow;
        font-family: $hind;

        @media screen and (max-width: 780px){
            font-size: 18px;
        }
    }

    .footer-title{
        p{
            @media screen and (max-width: 768px){
                text-align: center;
            }
        }

        ul > li {
            list-style-type: none;
            font-family: $hind;
            padding: 5px 0;

            @media screen and (max-width: 780px){
                padding: 2px 0;
                text-align: center;
            }

            .shortcuts{
                color: $white;
                text-decoration: none;
                font-size: 16px;
                padding: 5px 0;

                @media screen and (max-width: 780px){
                    font-size: 14px;
                }
            }
        }
    }

    .marina-socials{

        @media screen and (max-width: 768px) {
            text-align: center;
        }

        .social-icons{
            font-size: 28px;
            color: $yellow;
            margin-right: 10px;
        }
    }

    .link-row{
        @media screen and (max-width: 780px) {
            margin-top: 30px;
        }
    }

    .copyright{
        text-align: center;
        border-top: 1px solid $yellow;
        margin-top: 30px;

        p{
            padding-top: 30px;
            font-size: 14px;
            font-family: $hind;
        }
    }
}